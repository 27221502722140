import React from 'react';
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
  Navigate,
} from 'react-router-dom';

import LogInLayout from 'components/layout/LogInLayout/LogInLayout';
import LogInPage from 'pages/login/LogInPage';
import SingUpPage from 'pages/login/SignUpPage';

import ProtectedLayout from 'components/layout/ProtectedLayout/ProtectedLayout';
import TeamLayout from 'components/layout/TeamLayout/TeamLayout';
import OnboardingLayout from 'components/layout/OnboardingLayout/OnboardingLayout';

import OverviewPage from 'pages/team_view/overview';
import Insights from 'pages/team_view/Insights';

import AgendaPage from 'pages/agenda';
import AddEditTemplatePopUp from 'pages/agenda/add_edit/AddEditTemplatePopUp';
import DeleteTemplatePopUp from 'pages/agenda/delete/DeleteTemplatePopUp';
import FeedbackLoop from 'pages/FeedbackLoop';
import PersonalView from 'pages/PersonalView';
import MembersPage from 'pages/settings/members/MembersPage';
import TeamsPage from 'pages/settings/teams/TeamsPage';
import NotFound from 'pages/NotFound';

import { ThemeProvider } from 'styled-components';
import { theme } from 'styles/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ToastNotifications from 'components/ToastNotifications/ToastNotifications';
import ProfilePage from './pages/profile/ProfilePage';
import ProfileSetupStep from 'pages/onboarding/ProfileSetupStep';
import InviteMembersStep from 'pages/onboarding/InviteMembersStep';
import { authService } from 'globals/services/authService';
import { PROFILE_QUERY_KEY } from 'globals/hooks/useProfile';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/',
    loader: loginLoader,
    element: <LogInLayout />,
    children: [
      {
        path: 'login',
        element: <LogInPage />,
      },
      {
        path: 'signup',
        element: <SingUpPage />,
      },
    ],
  },
  {
    path: '/',
    loader: protectedLoader,
    errorElement: <NotFound />,
    children: [
      {
        element: <ProtectedLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="team-view" replace />,
          },
          {
            path: 'team-view',
            element: <TeamLayout />,
            children: [
              {
                index: true,
                element: <Navigate to="overview" replace />,
              },
              {
                path: 'overview',
                element: <OverviewPage />,
              },
              {
                path: 'insights',
                element: <Insights />,
              },
            ],
          },
          {
            path: 'agenda',
            element: <AgendaPage />,
            children: [
              {
                path: 'add',
                element: <AddEditTemplatePopUp />,
              },
              {
                path: ':agenda-template-id/edit',
                element: <AddEditTemplatePopUp />,
              },
              {
                path: ':agenda-template-id/delete',
                element: <DeleteTemplatePopUp />,
              },
            ],
          },
          {
            path: 'feedback-loop',
            element: <FeedbackLoop />,
          },
          {
            path: 'personal-view',
            element: <PersonalView />,
          },
          {
            path: 'settings',
            children: [
              {
                index: true,
                element: <Navigate to="members" replace />,
              },
              {
                path: 'members',
                element: <MembersPage />,
              },
              {
                path: 'teams',
                element: <TeamsPage />,
              },
              {
                path: 'departments',
                element: <h3>Departments</h3>,
              },
              {
                path: 'wages',
                element: <h3>Wages</h3>,
              },
              {
                path: 'profile',
                element: <ProfilePage />,
              },
            ],
          },
        ],
      },
      {
        path: 'onboarding',
        element: <OnboardingLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="profile" replace />,
          },
          {
            path: 'profile',
            element: <ProfileSetupStep />,
          },
          {
            path: 'invite-members',
            element: <InviteMembersStep />,
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ToastNotifications />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

async function loginLoader() {
  try {
    const profile = await authService.auth();
    queryClient.setQueryData(PROFILE_QUERY_KEY, profile);
    return redirect('/');
  } catch (error) {
    return null;
  }
}

async function protectedLoader() {
  try {
    const profile = await authService.auth();
    queryClient.setQueryData(PROFILE_QUERY_KEY, profile);
    return null;
  } catch (error) {
    return redirect('/login');
  }
}

export default App;
