import React, { FC, ReactNode, useState } from 'react';
import { Button, DropDownMenu } from 'components/forms';
import useOnClickOutside from 'globals/hooks/useOnClickOutside';

type Option = {
  label: string;
  value: string | number;
};
type ButtonWithDropDownProps = {
  onSelect: (value: any) => void;
  icon?: string;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'dark';
  size?: 'small' | 'medium' | 'large';
  options?: Option[];
  children?: ReactNode;
  direction?: 'up' | 'down';
  align?: 'left' | 'right';
  className?: string;
};

const ButtonWithDropDown: FC<ButtonWithDropDownProps> = ({
  onSelect,
  icon,
  disabled = false,
  variant = 'primary',
  size = 'medium',
  options = [],
  children,
  direction = 'down',
  align = 'right',
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdownVisibility = () => {
    setIsOpen(!isOpen);
  };
  const containerRef = React.createRef<HTMLDivElement>();
  useOnClickOutside(containerRef, () => setIsOpen(false));

  const selectOption = (value: string) => {
    onSelect(value);
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ position: 'relative' }} ref={containerRef}>
      <Button
        icon={icon}
        disabled={disabled}
        variant={variant}
        size={size}
        onClick={toggleDropdownVisibility}
        className={className}
      >
        {children}
      </Button>
      {isOpen && (
        <DropDownMenu
          options={options}
          onSelect={selectOption}
          size={size}
          align={align}
          direction={direction}
        />
      )}
    </div>
  );
};
export default ButtonWithDropDown;
