import styles from './PageHeader.module.scss';
import React from 'react';

export default function PageHeader({ title }: { title: string }) {
  return (
    <div className={styles.pageHeader}>
      <h2 className={styles.pageTitle}>{title}</h2>
    </div>
  );
}
