import React from 'react';
import classNames from 'classnames';
import styles from './Checkbox.module.scss';
import { Label } from 'components/forms';

type CheckboxProps = {
  label?: string;
  value: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

const Checkbox = ({
  label,
  value,
  onChange,
  className,
}: CheckboxProps): JSX.Element => (
  <div className={classNames(styles.container, className)}>
    <input
      type="checkbox"
      checked={value}
      onChange={onChange}
      className={styles.checkbox}
    />
    <Label text={label} className={styles.checkboxLabel} />
  </div>
);

export default Checkbox;
