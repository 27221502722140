import React, { useState, useEffect, useRef } from 'react';
import { DropDownMenu, Input } from 'components/forms';
import styles from './Select.module.scss';
import useOnClickOutside from 'globals/hooks/useOnClickOutside';

type Option = {
  label: string;
  value: string | number;
};

type SearchableSelectProps = {
  selectedValue: string | number;
  onValueChange: (value: string | number) => void;
  options: Option[][] | Option[];
  placeholder?: string;
  size?: 'medium' | 'large';
};

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  selectedValue,
  onValueChange,
  options,
  placeholder = '',
  size = 'medium',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const selectRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(selectRef, () => setIsOpen(false));

  const handleOptionSelect = (value: string | number) => {
    onValueChange(value);
    setIsOpen(false);
  };

  const toggleDropDownVisibility = () => {
    setIsOpen(!isOpen);
    setSearchTerm('');
  };

  useEffect(() => {
    const updateFilteredOptions = () => {
      if (searchTerm.trim() !== '') {
        const filtered = options.map((group) =>
          group.filter((option) =>
            option.label.toLowerCase().includes(searchTerm.toLowerCase()),
          ),
        );
        setFilteredOptions(filtered);
      } else {
        setFilteredOptions(options);
      }
    };

    updateFilteredOptions();
  }, [searchTerm, options]);

  const selectedOption = options
    .flat()
    .find((option) => option.value === selectedValue);

  return (
    <div className={styles.selectContainer} ref={selectRef}>
      <Input
        size={size}
        value={!isOpen && selectedOption ? selectedOption.label : searchTerm}
        placeholder={placeholder}
        onChange={(value) => setSearchTerm(value)}
        onClick={toggleDropDownVisibility}
        icon={isOpen ? 'RiArrowUpSLine' : 'RiArrowDownSLine'}
        iconPosition="right"
        readOnly={!isOpen}
        className={styles.input}
      />
      {isOpen && (
        <DropDownMenu
          value={selectedValue}
          options={filteredOptions}
          onSelect={handleOptionSelect}
          size={size}
          noOptionsMessage={`No results for + ${searchTerm}`}
        />
      )}
    </div>
  );
};

export default SearchableSelect;
