import React, { useState } from 'react';
import classNames from 'classnames';
import EmailPill from './EmailPill';
import { Input, Button } from 'components/forms';
import validateEmail from 'globals/utils/validateEmail';
import styles from './InviteMembersForm.module.scss';
import TooltipWrapper from 'components/Tooltip/Tooltip';

type InviteMembersFormProps = {
  onEmailsChange: (isEmailsValid: boolean, emails: string[]) => void;
};

const InviteMembersForm = ({ onEmailsChange }: InviteMembersFormProps) => {
  const [emailsInput, setEmailsInput] = useState<string>('');
  const [parsedEmails, setParsedEmails] = useState<string[]>([]);

  const parseEmails = () => {
    const newEmails = emailsInput
      .split(/[,\s]+/)
      .map((e) => e.trim())
      .filter(Boolean);
    const uniqueEmails = new Set([...parsedEmails, ...newEmails]);
    const updatedEmails = Array.from(uniqueEmails);
    setParsedEmails(updatedEmails);
    setEmailsInput('');

    onEmailsChange(isEmailsValid(updatedEmails), updatedEmails);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      parseEmails();
    }
  };

  const removeEmail = (email: string) => () => {
    const updatedEmails = parsedEmails.filter((e) => e !== email);
    setParsedEmails(updatedEmails);

    onEmailsChange(isEmailsValid(updatedEmails), updatedEmails);
  };

  const isEmailsValid = (emails: string[]): boolean => {
    return emails.every((e) => validateEmail(e)) && emails.length > 0;
  };

  return (
    <>
      <div className={styles.emailsContainer}>
        <div className={styles.inputWrapper}>
          <Input
            value={emailsInput}
            onChange={(value) => setEmailsInput(value)}
            size="medium"
            onKeyDown={handleKeyDown}
            placeholder="Enter emails"
          />
          <TooltipWrapper overlay="Enter emails first" disabled={!!emailsInput}>
            <Button
              className={styles.addButton}
              disabled={!emailsInput}
              onClick={parseEmails}
            >
              Add
            </Button>
          </TooltipWrapper>
        </div>
        {parsedEmails.length === 0 ? (
          <p className={classNames(styles.text, styles.helperText, 't-body2')}>
            [Additional hint: describe how to add users by bulk or emails with a
            comma]
          </p>
        ) : (
          <div className={styles.emailsList}>
            {parsedEmails.map((email, index) => (
              <EmailPill
                key={index}
                email={email}
                isValid={validateEmail(email)}
                removeEmail={removeEmail(email)}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default InviteMembersForm;
