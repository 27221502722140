import React from 'react';
import classNames from 'classnames';
import Avatar from 'components/Avatar/Avatar';
import styles from './UserChip.module.scss';

type UserChipProps = {
  userName?: string | null;
  userPic?: string | null;
  email?: string;
  size?: 'small' | 'medium' | 'large';
  className?: string;
};

export default function UserChip({
  userName,
  userPic,
  email,
  size = 'medium',
  className,
}: UserChipProps): JSX.Element {
  const onlyEmail = !userName;

  return (
    <div className={classNames(styles.userChip, className)}>
      <Avatar
        className={classNames(styles.userPic)}
        iconClassName={styles.icon}
        size={size}
        src={userPic}
        isRounded
      />
      <div className={styles.userInfoContainer}>
        <span className={styles.title}>{onlyEmail ? email : userName}</span>
        {userName && <span className={styles.subtitle}>{email}</span>}
      </div>
    </div>
  );
}
