import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import SidebarItem from './SidebarItem/SidebarItem';
import { Icon, Button } from 'components/forms';
import sidebarMenuConfig from 'globals/configs/sidebarMenuConfig';
import ProfileItem from './ProfileItem/ProfileItem';

import styles from './Sidebar.module.scss';

const DefaultSidebar = (): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isHoveredSideBar, setIsHoveredSideBar] = useState(false);

  const toggleSideBar = () => {
    setIsExpanded(!isExpanded);
    setIsHoveredSideBar(false);
  };

  // all except the last item
  const mainMenu = sidebarMenuConfig.slice(0, -1);
  // last item
  const settingsItem = sidebarMenuConfig.at(-1);

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setIsHoveredSideBar(true)}
      onMouseLeave={() => setIsHoveredSideBar(false)}
    >
      <div className={classNames(styles.header, styles.defaultHeader)}>
        {(isExpanded || !isHoveredSideBar) && <Icon size={32} name="logo" />}
        {isHoveredSideBar && (
          <Button
            icon={isExpanded ? 'RiExpandLeftLine' : 'RiExpandRightLine'}
            onClick={toggleSideBar}
            variant="tertiary"
          />
        )}
      </div>
      <div
        className={classNames(styles.menu, { [styles.expanded]: isExpanded })}
      >
        {mainMenu.map((item) => (
          <Fragment key={item.path}>
            <SidebarItem
              icon={item.icon}
              linkTo={item.path}
              label={item.label}
              expanded={isExpanded}
            />
            {item.separate && <div className={styles.separator} />}
          </Fragment>
        ))}
      </div>
      <div
        className={classNames(styles.footer, { [styles.expanded]: isExpanded })}
      >
        <SidebarItem
          icon={settingsItem?.icon}
          label={settingsItem?.label}
          linkTo={settingsItem?.path}
          expanded={isExpanded}
        />
        <ProfileItem />
      </div>
    </div>
  );
};

export default DefaultSidebar;
