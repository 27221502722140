import React from 'react';
import classNames from 'classnames';
import DropDownGroup from './DropDownGroup';
import styles from './DropDownMenu.module.scss';
import DropDownItem from './DropDownItem';

export type Option = {
  value: string | number;
  label: string;
  icon?: string;
};

type DropDownMenuProps = {
  value?: Option['value'];
  options: Option[][] | Option[];
  onSelect: (value: Option['value']) => void;
  size?: 'small' | 'medium' | 'large';
  align?: 'left' | 'right';
  direction?: 'up' | 'down';
  noOptionsMessage?: string;
  className?: string;
};

const DropDownMenu = ({
  value = '',
  options = [],
  onSelect,
  size = 'medium',
  align = 'right',
  direction = 'down',
  noOptionsMessage = 'No options available',
  className,
}: DropDownMenuProps): JSX.Element => {
  const hasOptions = options.length > 0;

  const hasGroupedOptions = (
    options: Option[] | Option[][],
  ): options is Option[][] => {
    return hasOptions && Array.isArray(options[0]);
  };

  // Filter out empty groups
  const filteredOptions = Array.isArray(options[0])
    ? (options as Option[][]).filter((group) => group.length > 0)
    : options;

  return (
    <div
      className={classNames(
        styles.container,
        styles[align],
        styles[direction],
        className,
      )}
    >
      <div className={styles.menu}>
        {!hasOptions && (
          <div className={classNames(styles.item, styles[size])}>
            {noOptionsMessage}
          </div>
        )}
        {hasGroupedOptions(filteredOptions) ? (
          filteredOptions.map((group: Option[], index: number) => (
            <DropDownGroup key={index}>
              {group.map((option) => (
                <DropDownItem
                  key={option.value}
                  option={option}
                  isSelected={option.value === value}
                  size={size}
                  onSelect={onSelect}
                />
              ))}
            </DropDownGroup>
          ))
        ) : (
          <DropDownGroup>
            {filteredOptions.map((option: Option) => (
              <DropDownItem
                key={option.value}
                option={option}
                isSelected={option.value === value}
                size={size}
                onSelect={onSelect}
              />
            ))}
          </DropDownGroup>
        )}
      </div>
    </div>
  );
};

export default DropDownMenu;
