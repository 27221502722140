import React, { useRef } from 'react';
import classNames from 'classnames';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Label } from 'components/forms';

import styles from './Editor.module.scss';

const CustomToolbar = () => (
  <div id="toolbar">
    <button className="ql-bold"></button>
    <button className="ql-italic"></button>
    <button className="ql-underline"></button>
    <button className="ql-list" value="ordered"></button>
    <button className="ql-list" value="bullet"></button>
    <button className="ql-link"></button>
  </div>
);

type EditorProps = {
  value: string;
  onChange?: (content: string) => void;
  label: string;
  size?: 'small' | 'large';
  placeholder?: string;
  description?: string;
  errorMessage?: string;
};

const Editor = ({
  value,
  onChange,
  label,
  size = 'small',
  placeholder = '',
  description = '',
  errorMessage = '',
}: EditorProps): JSX.Element => {
  const quillRef = useRef(null);

  const handleChange = (content: string) => {
    if (onChange) onChange(content);
  };

  return (
    <div className={classNames(styles.editorGroup, styles[size])}>
      <Label text={label} size={size} />
      <div
        className={classNames(styles.editorContainer, {
          [styles.error]: errorMessage,
        })}
      >
        <ReactQuill
          ref={quillRef}
          value={value}
          onChange={handleChange}
          modules={{
            toolbar: {
              container: '#toolbar',
            },
          }}
          formats={[
            'header',
            'bold',
            'italic',
            'underline',
            'list',
            'bullet',
            'link',
            'image',
          ]}
          placeholder={placeholder}
        />
        <CustomToolbar />
      </div>
      {description && <p className={styles.helperText}>{description}</p>}
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default Editor;
