import React, { useEffect } from 'react';
import Item from './Item';
import ItemCreate from './ItemCreate';
import styled from 'styled-components';
import { Body1, Body2, Helper } from 'styles/Typography';
import { useAgendaStore } from 'store/useAgendaStore';
import { Button } from 'components/forms';
import { useNavigate } from 'react-router-dom';
import { NoContentPanel } from 'components/features';

const ItemList = () => {
  const navigate = useNavigate();
  const { agendaTemplates, setLoading, fetchAgendaTemplates } =
    useAgendaStore();
  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await fetchAgendaTemplates();
      setLoading(false);
    };
    loadData();
  }, [fetchAgendaTemplates, setLoading]);

  return (
    <Container>
      {agendaTemplates.length > 0 ? (
        <>
          <Name>
            <Body2>[Manage meeting agenda templates]</Body2>
          </Name>
          <ContainerAgendas>
            {agendaTemplates.map((item, index) => (
              <Item
                key={index}
                template_id={item.id}
                name={item.title}
                description={item.description}
              />
            ))}
            <ItemCreate />
          </ContainerAgendas>
        </>
      ) : (
        <>
          <NoContentPanel
            title="You don’t have any agenda templates created"
            subtitle="[Additional description]"
            buttonTitle="Create template"
            action={() => navigate(`/agenda/add`)}
          />
          <ContainerHelp>
            <Body1>You have 4 upcoming meetings without agenda</Body1>
            <Helper>[Additional description]</Helper>
            <Button variant={'secondary'}>Show meetings w/o agenda</Button>
          </ContainerHelp>
        </>
      )}
    </Container>
  );
};

const ContainerHelp = styled.div`
  height: 170px;
  width: 472px;
  padding: 32px;
  border-radius: 8px;
  margin: 24px 0;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.Neutral['30']};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const Container = styled.div`
  width: max-content;
  margin: 0 auto;
`;
const Name = styled.div`
  color: ${({ theme }) => theme.colors.Neutral['60']};
  margin-bottom: 24px;
`;

const ContainerAgendas = styled.div`
  display: grid;
  gap: 20px;
  max-width: 880px;
  grid-template-columns: 1fr 1fr 1fr;
`;

export default ItemList;
