const BACKEND_URL = (() => {
  if (process.env.REACT_APP_ENV === 'production') {
    return 'https://api.spryplan.com';
  }
  return 'http://localhost:8000';
})();

const getHeaders = (): HeadersInit => {
  return {
    'Content-Type': 'application/json',
  };
};

type ValidationError = {
  loc: string[];
  msg: string;
};

const handleResponse = async <T>(response: Response): Promise<T> => {
  if (!response.ok) {
    const errorData = await response.json();

    if (response.status === 422) {
      const errors = (errorData.detail as ValidationError[]).reduce<
        Record<string, string>
      >((acc, curr) => {
        const key = curr.loc.slice(1).join('.');
        acc[key] = curr.msg.split(', ')[1];
        return acc;
      }, {});

      throw new Error(JSON.stringify({ status: response.status, errors }));
    }

    throw new Error(
      JSON.stringify({
        status: response.status,
        message: errorData.message || 'Something went wrong',
      }),
    );
  }
  return response.json();
};

export const getRequest = async <T>(
  endpoint: string,
  params: Record<string, string> = {},
): Promise<T> => {
  const url = new URL(`${BACKEND_URL}${endpoint}`);

  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key]),
  );

  const response = await fetch(url, {
    method: 'GET',
    headers: getHeaders(),
    credentials: 'include',
  });

  return handleResponse<T>(response);
};

export const postRequest = async <T>(
  endpoint: string,
  data: unknown,
): Promise<T> => {
  const response = await fetch(`${BACKEND_URL}${endpoint}`, {
    method: 'POST',
    headers: getHeaders(),
    credentials: 'include',
    body: JSON.stringify(data),
  });

  return handleResponse<T>(response);
};

export const putRequest = async <T>(
  endpoint: string,
  data: unknown,
): Promise<T> => {
  const response = await fetch(`${BACKEND_URL}${endpoint}`, {
    method: 'PUT',
    headers: getHeaders(),
    credentials: 'include',
    body: JSON.stringify(data),
  });

  return handleResponse<T>(response);
};

export const deleteRequest = async <T>(endpoint: string): Promise<T> => {
  const response = await fetch(`${BACKEND_URL}${endpoint}`, {
    method: 'DELETE',
    headers: getHeaders(),
    credentials: 'include',
  });

  return handleResponse<T>(response);
};

const api = {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} as const;

export default api;
