import React, { HTMLAttributes } from 'react';
import styles from './Pill.module.scss';
import classNames from 'classnames';
import { RiCloseFill } from '@remixicon/react';
import TransparentButton from 'components/TransparentButton/TransparentButton';

type PillColor = 'default' | 'error' | 'success' | 'info';

type PillProps = HTMLAttributes<HTMLDivElement> & {
  label: string;
  color: PillColor;
  className?: string;
  onDelete?: () => void;
};

export default function Pill({
  label,
  color = 'default',
  className,
  onDelete,
  ...rest
}: PillProps) {
  return (
    <div
      className={classNames(styles.pill, styles[color], className)}
      {...rest}
    >
      <span className={classNames('t-helper', styles.text)}>{label}</span>
      {onDelete && (
        <TransparentButton
          onClick={() => onDelete()}
          className={styles.deleteButton}
        >
          <RiCloseFill size={16} />
        </TransparentButton>
      )}
    </div>
  );
}
