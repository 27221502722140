import { Team } from 'globals/types/Team';

export const mockTeams = [
  {
    id: 1,
    name: 'Team 1',
    members: 5,
    manager: {
      name: 'John Doe',
      email: 'testmail@gmail.com',
      photo_url: null,
    },
  },
  {
    id: 2,
    name: 'Team 2',
    members: 6,
    manager: {
      name: 'Jane Doe',
      email: 'tes2tsad2mail@gmail.com',
      photo_url: null,
    },
  },
] as Team[];
