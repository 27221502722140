import React from 'react';
import Tooltip from 'rc-tooltip';
import { TooltipProps } from 'rc-tooltip/lib/Tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import styles from './Tooltip.module.scss';

type TooltipWrapperProps = TooltipProps & {
  children: React.ReactNode;
  // prop to turn off tooltip
  disabled?: boolean;
};

const TooltipWrapper = ({
  children,
  disabled,
  ...props
}: TooltipWrapperProps) => {
  if (disabled) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      showArrow={false}
      placement="top"
      mouseEnterDelay={0.2}
      {...props}
      classNames={{
        root: styles.tooltip,
        body: styles.tooltipContainer,
      }}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipWrapper;
