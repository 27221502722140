import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { ImageEdit } from 'components/features';
import { Input, Button } from 'components/forms';
import { Profile } from 'globals/services/profileService';

import styles from './ProfileForm.module.scss';

type ProfileFormProps = {
  profile: Profile | undefined;
  isPending: boolean;
  updateProfile: (data: FormData) => Promise<AxiosResponse>;
};

type FormInputs = Omit<Profile, 'photo_url'>;

const ProfileForm = ({
  profile,
  isPending,
  updateProfile,
}: ProfileFormProps) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { dirtyFields },
  } = useForm<FormInputs>({
    defaultValues: {
      name: '',
      email: '',
    },
  });
  const [photoPreview, setPhotoPreview] = React.useState<string>('');

  useEffect(() => {
    if (profile) {
      setValue('name', profile.name || '', { shouldDirty: false });
      setValue('email', profile.email || '', { shouldDirty: false });
      setPhotoPreview(profile.photo_url || '');
    }
  }, [profile, setValue]);

  const handlePhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setPhotoPreview(reader.result as string);

        const formDataToSend = new FormData();
        formDataToSend.append('photo_file', file);
        formDataToSend.append('name', watch('name'));

        try {
          await updateProfile(formDataToSend);
          toast.success('Photo updated successfully');
        } catch (error) {
          console.log(error);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = async (data: FormInputs) => {
    const formDataToSend = new FormData();
    if (data.name) {
      formDataToSend.append('name', data.name);
    }
    try {
      await updateProfile(formDataToSend);
      if (dirtyFields.name) {
        toast.success('Name updated successfully');
        reset(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ImageEdit
        photoPreview={photoPreview}
        onPhotoChange={handlePhotoChange}
      />
      <div className={styles.formContainer}>
        <div className={styles.nameRow}>
          <div className={styles.nameInput}>
            <Input
              type="text"
              label="Full name"
              {...register('name')}
              onChange={(value: string) =>
                setValue('name', value, { shouldDirty: true })
              }
              value={watch('name')}
            />
          </div>
          {dirtyFields.name && (
            <Button
              variant="secondary"
              disabled={isPending}
              onClick={handleSubmit(onSubmit)}
              className={styles.saveButton}
            >
              Save
            </Button>
          )}
        </div>

        <Input
          type="email"
          label="Email"
          value={watch('email')}
          readOnly
          containerClassName={styles.emailContainer}
        />
      </div>
    </form>
  );
};

export default ProfileForm;
