import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import PageContainer from 'components/layout/PageContainer/PageContainer';
import { Column, DataTable } from 'components/DataTable/DataTable';
import { RowActions } from 'components/DataTable/RowActions';
import { UserChip } from 'components/features';
import Pill from 'components/Pill/Pill';
import { useMembers } from 'globals/hooks/useMembers';
import { Member } from 'globals/types/Member';
import { Input, Button } from 'components/forms';
import styles from './MembersPage.module.scss';
import { useInviteMembersDialog } from './InviteMembersDialog/InviteMembersDialog';
import { useConfirmDialog } from 'components';

export default function MembersPage() {
  const { membersList, deleteMember, inviteMembers, resendInvitation } =
    useMembers();
  const { data: members } = membersList.data;
  const [searchQuery, setSearchQuery] = useState('');
  const { modal: inviteMembersDialog, invoke: openInviteMembersDialog } =
    useInviteMembersDialog();
  const { modal: confirmDeleteDialog, invoke: openConfirmDeleteDialog } =
    useConfirmDialog({
      title: 'Delete Member',
      content: 'Are you sure you want to delete this member?',
    });

  const handleDelete = async (id: number) => {
    const isConfirmed = await openConfirmDeleteDialog();
    if (isConfirmed) {
      deleteMember
        .mutateAsync(id)
        .then(() => {
          toast.success('Member deleted successfully');
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleSendInvitations = async () => {
    const emails = await openInviteMembersDialog();
    if (emails) {
      inviteMembers
        .mutateAsync({ emails })
        .then(() => {
          toast.success('Invitations sent successfully');
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  };

  const handleResendInvitation = (id: number) => {
    resendInvitation
      .mutateAsync(id)
      .then(() => {
        toast.success('Invitation resent successfully');
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const getRowActions = (item: Member) => {
    const actions = [
      {
        label: 'Edit',
        handler: (item) => {
          console.log(item, 'edit');
        },
      },
      {
        label: 'Delete',
        handler: (item) => {
          handleDelete(item.id);
        },
      },
    ];
    if (item.status === 'pending') {
      actions.push({
        label: 'Resend Invite',
        handler: (item) => {
          handleResendInvitation(item.id);
        },
      });
    }
    return actions;
  };

  const filteredMembers = useMemo(() => {
    if (!searchQuery) {
      return members;
    }

    return members.filter((member) => {
      return (
        (member.name &&
          member.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
        member.email.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [members, searchQuery]);

  const isTableLoading = [
    membersList,
    deleteMember,
    inviteMembers,
    resendInvitation,
  ].some((q) => q.isPending);

  const statusMapping = {
    active: 'success',
    pending: 'info',
  };

  const columns: Column<Member>[] = [
    {
      key: 'email',
      header: `Members (${filteredMembers.length})`,
      align: 'left',
      render: (_, user: Member) => (
        <UserChip
          userName={user.name}
          userPic={user.photo_url}
          email={user.email}
        />
      ),
    },
    {
      key: 'department',
      header: 'Department',
      align: 'left',
    },
    {
      key: 'team',
      header: 'Team',
      align: 'left',
    },
    {
      key: 'rate',
      header: 'Wage',
      align: 'center',
      // render: (value) => `$${value}`,
    },
    {
      key: 'status',
      width: '128px',
      header: 'Status',
      align: 'left',
      render: (value: string) => (
        <Pill label={value} color={statusMapping[value]} />
      ),
    },
    {
      key: 'actions',
      header: '',
      width: '100px',
      align: 'right',
      render: (_, item) => (
        <RowActions item={item} actions={getRowActions(item)} />
      ),
    },
  ];

  return (
    <>
      <PageHeader title="Members" />
      <PageContainer>
        <div className={styles.membersActions}>
          <div>
            <Input
              placeholder="Search by name or email"
              type="text"
              size="medium"
              containerClassName={styles.membersSearch}
              icon="RiSearchLine"
              onChange={setSearchQuery}
              value={searchQuery}
            />
          </div>
          <Button variant="primary" onClick={handleSendInvitations}>
            Invite Members
          </Button>
        </div>
        <DataTable
          data={filteredMembers}
          columns={columns}
          isLoading={isTableLoading}
        />
        {inviteMembersDialog}
        {confirmDeleteDialog}
      </PageContainer>
    </>
  );
}
