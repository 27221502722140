import { useEffect, useState } from 'react';

export const useContentOverflow = (contentRef) => {
  const [hasOverflowTop, setHasOverflowTop] = useState(false);
  const [hasOverflowBottom, setHasOverflowBottom] = useState(false);

  const checkOverflow = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      setHasOverflowTop(scrollTop > 0);
      setHasOverflowBottom(scrollTop + clientHeight < scrollHeight);
    }
  };

  useEffect(() => {
    checkOverflow();
    const element = contentRef.current;
    if (element) {
      element.addEventListener('scroll', checkOverflow);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', checkOverflow);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { hasOverflowTop, hasOverflowBottom };
};
