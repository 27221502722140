import React from 'react';
import { Outlet } from 'react-router-dom';
import { Tabs } from 'components/navigation';

import styles from './TeamLayout.module.scss';

const TeamLayout = (): JSX.Element => {
  const tabs = [
    { label: 'Overview', linkTo: '/team-view/overview' },
    { label: 'Insights', linkTo: '/team-view/insights' },
  ];
  return (
    <>
      <div className={styles.contentHeader}>
        <Tabs tabs={tabs} />
      </div>
      <div className={styles.contentMain}>
        <Outlet />
      </div>
    </>
  );
};

export default TeamLayout;
