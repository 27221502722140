import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { authService } from 'globals/services/authService';
import { GoogleSignInButton } from 'components/forms';
import { RiArrowRightLine } from '@remixicon/react';
import Logo from 'assets/images/logo_image.svg';

import styles from './Auth.module.scss';

const LoginPage = (): JSX.Element => {
  const handleLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      const result = await authService.authWithGoogle();
      if (result?.uri) {
        window.location.assign(result.uri);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error on login');
    }
  };

  return (
    <div className={styles.loginContainer}>
      <img src={Logo} alt="Logo" className={styles.logoImage} />
      <div className={styles.textContainer}>
        <div className={styles.textHeading}>Log in</div>
        <div className={styles.googleLogin}>
          <GoogleSignInButton onClick={handleLogin} />
        </div>
      </div>
      <div className={styles.links}>
        <span className={classNames(styles.authLink, styles.linksText)}>
          Don't have an account?&nbsp;
          <a
            href="/signup"
            className={classNames(styles.linkWithIcon, styles.customLink)}
          >
            Sign up <RiArrowRightLine size={14} />
          </a>
        </span>
      </div>
    </div>
  );
};

export default LoginPage;
