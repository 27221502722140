import React from 'react';
import styles from './Label.module.scss';
import classNames from 'classnames';

type LabelProps = {
  text?: string;
  size?: 'small' | 'medium' | 'large';
  className?: string;
} & React.LabelHTMLAttributes<HTMLLabelElement>;

const Label = ({
  text,
  size = 'medium',
  className,
  ...props
}: LabelProps): JSX.Element | null => {
  if (!text) return null;

  return (
    <label
      className={classNames(styles.label, styles[size], className)}
      {...props}
    >
      {text}
    </label>
  );
};

export default Label;
