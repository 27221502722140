import React from 'react';
import styles from './EmailPill.module.scss';
import classNames from 'classnames';
import TooltipWrapper from 'components/Tooltip/Tooltip';
import Pill from 'components/Pill/Pill';

type EmailPillProps = {
  email: string;
  isValid: boolean;
  removeEmail: (v: string) => void;
};

export default function EmailPill({
  email,
  isValid,
  removeEmail,
}: EmailPillProps) {
  const validPill = (
    <Pill
      label={email}
      color="default"
      className={classNames(styles.emailPill, !isValid && styles.invalid)}
      onDelete={() => removeEmail(email)}
    />
  );

  const invalidPill = (
    <TooltipWrapper overlay="Email is invalid!">{validPill}</TooltipWrapper>
  );

  return isValid ? validPill : invalidPill;
}
