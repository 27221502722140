import React, { useEffect, useState } from 'react';

import { Button } from 'components/forms';
import { Body2Medium, Body2 } from 'styles/Typography';
import { getRequest, deleteRequest } from 'globals/utils/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useAgendaStore } from '../../../store/useAgendaStore';
import Dialog from 'components/Dialog/Dialog';

const DeleteTemplatePopUp = () => {
  const navigate = useNavigate();
  const { setLoading, fetchAgendaTemplates } = useAgendaStore();
  const { agenda_template_id } = useParams();

  const [title, setTitle] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (agenda_template_id) {
        setLoading(true);
        try {
          const result = await getRequest(`/agenda/${agenda_template_id}`);
          setTitle(result.title);
        } catch (error) {
          console.error('Error fetching data:', error);
          navigate('/agenda');
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [agenda_template_id, navigate, setLoading]);

  const handleClose = () => {
    navigate('/agenda');
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteRequest(`/agenda/${agenda_template_id}/`);
      handleClose();
      await fetchAgendaTemplates();
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  if (!title) return null;

  return (
    <Dialog
      title={'Delete agenda template'}
      onClose={() => handleClose()}
      dialogControlButtons={
        <>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="dark" onClick={handleDelete}>
            Delete
          </Button>
        </>
      }
      width={'400px'}
    >
      <Body2>
        Are you sure you want to delete template{' '}
        <Body2Medium>{title}</Body2Medium>? This action cannot be undone.
      </Body2>
    </Dialog>
  );
};

export default DeleteTemplatePopUp;
