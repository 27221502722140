import React from 'react';
import styles from './DataTable.module.scss';
import ButtonWithDropDown from 'components/forms/Button/ButtonWithDropDown';

type RowActionsProps<T> = {
  item: T;
  actions: {
    label: string;
    handler: (item: T) => void;
  }[];
};

export const RowActions = <T extends Record<string, any>>({
  item,
  actions,
}: RowActionsProps<T>) => {
  const options = actions.map((action) => ({
    label: action.label,
    value: action.label,
  }));

  const handleChange = (value: string) => {
    const action = actions.find((a) => a.label === value);
    if (action) {
      action.handler(item);
    }
  };

  return (
    <div className={styles.rowActionsContainer}>
      <ButtonWithDropDown
        icon="RiMore2Fill"
        variant="secondary"
        size="medium"
        options={options}
        onSelect={handleChange}
      />
    </div>
  );
};
