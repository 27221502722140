export const colors = {
  Neutral: {
    80: '#1A1A1A',
    70: '#474747',
    60: '#737373',
    50: '#919191',
    40: '#CACACC',
    30: '#E3E3E5',
    25: '#EBEBED',
    20: '#F0F0F2',
    15: '#F8F8F9',
    10: '#FBFBFD',
    0: '#FFFFFF',
  },
  Yellow: {
    65: '#C59511',
    60: '#DBA91F',
    55: '#F3C332',
    50: '#FECF40',
    40: '#FFD86E',
    30: '#FADD8C',
    20: '#FDEFC8',
  },
  Red: {
    60: '#F5222D',
    20: '#FFCFD9',
  },
  Green: {
    20: '#B0FAC9',
  },
  Blue: {
    20: '#D7E6FE',
  },
  Chart: {
    1: '#165DFF',
    2: '#14C9C9',
    3: '#F7BA1E',
  },
};
