import React from 'react';
import { useLocation } from 'react-router-dom';
import sidebarMenuConfig from 'globals/configs/sidebarMenuConfig';
import SubmenuSidebar from './SubmenuSidebar';
import DefaultSidebar from './DefaultSidebar';

const Sidebar = (): JSX.Element => {
  const location = useLocation();
  const currentPath = location.pathname.split('/')[1];

  const itemWithSubmenu = sidebarMenuConfig.find(
    (item) => item.subMenu && item.path === currentPath,
  );

  return itemWithSubmenu ? (
    <SubmenuSidebar selectedItem={itemWithSubmenu} />
  ) : (
    <DefaultSidebar />
  );
};

export default Sidebar;
