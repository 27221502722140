import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/forms';
import { toast } from 'react-toastify';
import styles from './OnboardingStep.module.scss';
import classNames from 'classnames';
import { useMembers } from 'globals/hooks/useMembers';
import { InviteMembersForm } from 'components/features';

const InviteMembersStep = () => {
  const navigate = useNavigate();
  const { inviteMembers } = useMembers(false);
  const [isEmailsValid, setIsEmailsValid] = useState<boolean>(false);
  const [parsedEmails, setParsedEmails] = useState<string[]>([]);

  const handleSendInvitationsClick = () => {
    inviteMembers
      .mutateAsync({ emails: parsedEmails })
      .then(() => {
        toast.success('Invitations sent successfully');
        navigate('/team-view');
      })
      .catch((e) => {
        console.log(e);
        toast.error('Failed to send invitations');
      });
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <h2>Invite members</h2>
        <p className={classNames(styles.text, 't-body2')}>
          [Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.]
        </p>
        <div style={{ marginTop: '32px' }}>
          <InviteMembersForm
            onEmailsChange={(valid, emails) => {
              setIsEmailsValid(valid);
              setParsedEmails(emails);
            }}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerContainer}>
          <Button
            variant="tertiary"
            size="large"
            onClick={() => navigate('/team-view')}
          >
            Skip this step
          </Button>
          <Button
            variant="primary"
            disabled={!isEmailsValid}
            size="large"
            onClick={() => handleSendInvitationsClick()}
          >
            {/* TODO: Implement isLoading for a Button */}
            <span className="t-subheader1">
              {inviteMembers.isPending ? 'Sending...' : 'Send invitations'}
            </span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default InviteMembersStep;
