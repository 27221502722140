import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, PageContainer } from 'components';
import { Button } from 'components/forms';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import { useProfile } from 'globals/hooks';
import { ProfileForm } from 'components/features';
import { toast } from 'react-toastify';

import styles from './ProfilePage.module.scss';

const ProfilePage = () => {
  const { profile, isPending, updateProfile, logout, deleteAccount } =
    useProfile();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const handleDeleteAccount = async () => {
    try {
      await deleteAccount();
      navigate('/login');
    } catch (error) {
      toast.error('Failed to delete account');
    }
  };

  return (
    <>
      <PageHeader title="Profile" />
      <PageContainer>
        <div className={styles.container}>
          <ProfileForm
            profile={profile}
            isPending={isPending}
            updateProfile={updateProfile}
          />
          <Divider className={styles.divider} />
          <div className={styles.buttonsContainer}>
            <Button variant="tertiary" onClick={handleLogout}>
              Logout
            </Button>
            <Button
              variant="tertiary"
              className={styles.deleteButton}
              onClick={handleDeleteAccount}
            >
              Delete Account
            </Button>
          </div>
        </div>
      </PageContainer>
    </>
  );
};

export default ProfilePage;
