import React from 'react';
import classNames from 'classnames';
import { Icon } from 'components/forms';
import { Option } from './DropDownMenu';
import styles from './DropDownMenu.module.scss';

export type DropDownItemProps = {
  option: Option;
  isSelected: boolean;
  size: 'small' | 'medium' | 'large';
  onSelect: (value: Option['value']) => void;
};

const DropDownItem = ({
  option,
  isSelected,
  size,
  onSelect,
}: DropDownItemProps): JSX.Element => {
  const handleClick = () => onSelect(option.value);

  return (
    <div
      className={classNames(styles.item, styles[size], {
        [styles.selected]: isSelected,
      })}
      onClick={handleClick}
    >
      <div className={styles.itemLabel}>
        {option.icon && (
          <Icon className={styles.itemIcon} name={option.icon} size={18} />
        )}
        <p className="t-body2">{option.label}</p>
      </div>
      {isSelected && <Icon size={18} name="RiCheckLine" />}
    </div>
  );
};

export default DropDownItem;
