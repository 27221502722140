import React from 'react';
import { Button } from 'components/forms';
import { useProfile } from 'globals/hooks';
import { ProfileForm } from 'components/features';
import styles from './OnboardingStep.module.scss';
import { useNavigate } from 'react-router-dom';

export default function ProfileSetupStep() {
  const { profile, isPending, updateProfile } = useProfile();
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.profileForm}>
          <ProfileForm
            profile={profile}
            isPending={isPending}
            updateProfile={updateProfile}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerContainer}>
          <Button
            variant="primary"
            size="large"
            onClick={() => navigate('/onboarding/invite-members')}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
}
