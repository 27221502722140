import React from 'react';
import { Input } from 'components/forms/index';

const PersonalView = () => {
  return (
    <>
      <p>Personal view</p>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          gap: '20px',
          marginLeft: '20px',
          maxWidth: '600px',
        }}
      >
        <Input
          label="Medium"
          size="medium"
          id="test"
          placeholder="Type here"
          icon="RiSearchLine"
          description="Description"
        />
        <Input
          label="Large right icon"
          size="large"
          placeholder="Type here"
          icon="RiArrowDownSLine"
          iconPosition="right"
        />
        <Input disabled label="Disabled" />
        <Input
          label="With error"
          size="large"
          placeholder="Type here"
          errorMessage="Error text"
        />
      </div>
    </>
  );
};

export default PersonalView;
