import React from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { authService } from 'globals/services/authService';
import { RiArrowRightLine } from '@remixicon/react';
import { GoogleSignInButton } from 'components/forms';
import Logo from 'assets/images/logo_image.svg';

import styles from './Auth.module.scss';

const SignupPage = (): JSX.Element => {
  const handleSignUp = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      const result = await authService.authWithGoogle();
      if (result?.uri) {
        window.location.assign(result.uri);
      }
    } catch (error) {
      console.error('Error on signup:', error);
      toast.error('Error on signup');
    }
  };

  return (
    <div className={styles.loginContainer}>
      <img src={Logo} alt="Logo" className={styles.logoImage} />
      <div className={styles.textContainer}>
        <div className={styles.textHeading}>
          Sign up to get all the features
        </div>
        <div className={styles.googleLogin}>
          <GoogleSignInButton onClick={handleSignUp} />
        </div>
      </div>
      <div className={styles.links}>
        <span className={classNames(styles.authLink, styles.linksText)}>
          Already have an account?&nbsp;
          <a
            href="/login"
            className={classNames(styles.linkWithIcon, styles.customLink)}
          >
            Login <RiArrowRightLine size={14} />
          </a>
        </span>
        <span className={classNames(styles.terms, styles.linksText)}>
          By using Spry Plan you agree to the{' '}
          <a
            className={styles.customLink}
            href="https://spryplan.com/terms_of_use"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            className={styles.customLink}
            href="https://spryplan.com/privacy_policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </span>
      </div>
    </div>
  );
};

export default SignupPage;
