import { AxiosResponse } from 'axios';
import apiClient from 'globals/apiClient';

export type Profile = {
  name: string;
  email: string;
  photo_url?: string;
};

export const profileService = {
  getProfile: async (): Promise<Profile> => {
    const { data } = await apiClient.get('/profile/');
    return data;
  },

  updateProfile: (profileData: FormData): Promise<AxiosResponse> => {
    return apiClient.put('/profile/', profileData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  deleteAccount: (): Promise<AxiosResponse> => {
    return apiClient.get('/auth/delete');
  },
};
