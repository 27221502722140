import React from 'react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './Tabs.module.scss';

export type TabProps = {
  label: string;
  linkTo: string;
};

const Tab = ({ label, linkTo }: TabProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const normalizePath = (path: string): string => path.replace(/\/+$/, '');
  const isActive = normalizePath(location.pathname) === normalizePath(linkTo);

  return (
    <div
      className={classNames(styles.tabContainer, {
        [styles.selected]: isActive,
      })}
      onClick={() => navigate(linkTo)}
    >
      <div className={styles.textContainer}>
        <span className="t-body1">{label}</span>
      </div>
      {isActive && <div className={styles.border} />}
    </div>
  );
};

export default Tab;
