import React, { useState } from 'react';
import styled from 'styled-components';
import { Body1Bold, Body2 } from 'styles/Typography';
import { Button } from 'components/forms';
import gradientImage from 'assets/images/gradient/gradient_1.png'; // Імпортуємо зображення

const Banner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const handleShowBanner = () => {
    setShowBanner(!showBanner);
  };
  if (!showBanner) return null;
  return (
    <BannerContainer>
      <BannerText>
        <BannerTitle>
          <Body1Bold>Get full power of Spry Agenda</Body1Bold>
        </BannerTitle>
        <BannerDescription>
          <Body2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam.
          </Body2>
        </BannerDescription>
      </BannerText>
      <Button variant="dark" icon="RiChromeLine">
        Get Chrome extension
      </Button>
      <Button
        variant="tertiary"
        icon="RiCloseLine"
        onClick={handleShowBanner}
      />
    </BannerContainer>
  );
};

const BannerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  gap: 10px;

  background-image: url(${gradientImage}); /* Додаємо фонове зображення */
  background-size: cover;
  background-position: center;
`;

const BannerText = styled.div`
  flex: 1;
  margin-right: 16px;
`;

const BannerTitle = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.colors.Neutral['80']};
`;

const BannerDescription = styled.p`
  margin: 8px 0 0;
  color: ${({ theme }) => theme.colors.Neutral['70']};
`;

export default Banner;
