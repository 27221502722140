import React from 'react';
import styles from './NoContentPanel.module.scss';
import { Button } from 'components/forms';

type NoContentPanelProps = {
  title: string;
  subtitle?: string;
  buttonTitle?: string;
  action: () => void;
};

export default function NoContentPanel({
  title,
  subtitle,
  buttonTitle,
  action,
}: NoContentPanelProps): JSX.Element {
  const showButton = buttonTitle && typeof action === 'function';
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{subtitle}</p>
      {showButton && (
        <Button className={styles.button} variant="primary" onClick={action}>
          {buttonTitle}
        </Button>
      )}
    </div>
  );
}
