import React, { FC, MouseEventHandler, ReactNode } from 'react';
import classNames from 'classnames';
import { Icon } from 'components/forms';
import styles from './Button.module.scss';

type DefaulProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonProps = Omit<DefaulProps, 'type'> & {
  onClick: MouseEventHandler<HTMLButtonElement>;
  icon?: string;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'dark';
  size?: 'small' | 'medium' | 'large';
  children?: ReactNode;
  className?: string;
};

const Button: FC<ButtonProps> = ({
  onClick,
  icon,
  disabled = false,
  variant = 'primary',
  size = 'medium',
  children,
  className,
  ...htmlButtonProps
}) => {
  const content =
    typeof children === 'string' ? (
      <p className={'t-subheader1'}>{children}</p>
    ) : (
      children
    );
  return (
    <button
      className={classNames(
        styles.button,
        styles[`button-${variant}`],
        styles[`button-${size}`],
        { [styles['button-disabled']]: disabled },
        { [styles[`button-icon-${size}`]]: !content },
        className,
      )}
      onClick={onClick}
      disabled={disabled}
      {...htmlButtonProps}
    >
      {icon && <Icon name={icon} size={getIconSize(size)} />}
      {content}
    </button>
  );
};

const getIconSize = (buttonSize: string): number => {
  return buttonSize === 'small' ? 16 : 18;
};

export default Button;
