import { useState, useMemo } from 'react';

type SortDirection = 'asc' | 'desc' | null;

export const useSortedData = <TData extends Record<string, any>>(
  data: TData[],
) => {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof TData | null;
    direction: SortDirection;
  }>({ key: '' as keyof TData, direction: null });

  const sortedData = useMemo(() => {
    if (!sortConfig.key || !sortConfig.direction) return data;

    return [...data].sort((a, b) => {
      const aValue = a[sortConfig.key!];
      const bValue = b[sortConfig.key!];

      // Handle string comparison case-insensitively
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = aValue.localeCompare(bValue, undefined, {
          sensitivity: 'base',
        });
        return sortConfig.direction === 'asc' ? comparison : -comparison;
      }

      // Handle number comparison
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [data, sortConfig]);

  const handleSort = (key: keyof TData) => {
    setSortConfig((current) => {
      // If clicking on a different column, start with ascending sort
      if (current.key !== key) {
        return { key, direction: 'asc' };
      }

      // If clicking on the same column, cycle through: asc -> desc -> null
      const nextDirection =
        current.direction === 'asc'
          ? 'desc'
          : current.direction === 'desc'
            ? null
            : 'asc';

      return {
        key: nextDirection === null ? null : key,
        direction: nextDirection,
      };
    });
  };

  return { sortedData, sortConfig, handleSort };
};
