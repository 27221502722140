import React from 'react';
import { Button } from 'components/forms';
import Dialog, { DialogProps } from './Dialog';
import { usePromiseModal } from '@prezly/react-promise-modal';
import styles from './Dialog.module.scss';

type ConfirmDialogProps = Omit<DialogProps, 'dialogActions'> & {
  onConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
};

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  onConfirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  ...props
}) => {
  return (
    <Dialog
      {...props}
      contentClassName={styles.confirmContent}
      dialogActions={
        <>
          <Button variant="tertiary" onClick={props.onDismiss}>
            {cancelText}
          </Button>
          <Button variant="dark" onClick={onConfirm}>
            {confirmText}
          </Button>
        </>
      }
    />
  );
};

// use modal as a hook (returns a promise)
type UseConformDialogOptions = {
  title: string;
  content: React.ReactNode;
};

export const useConfirmDialog = ({
  title,
  content,
}: UseConformDialogOptions) => {
  return usePromiseModal(({ show, onSubmit, onDismiss }) => (
    <ConfirmDialog
      isOpen={show}
      title={title}
      onDismiss={onDismiss}
      onConfirm={() => onSubmit(true)}
    >
      {content}
    </ConfirmDialog>
  ));
};
