import React, { useMemo, useState } from 'react';
import PageHeader from 'components/layout/PageHeader/PageHeader';
import PageContainer from 'components/layout/PageContainer/PageContainer';
import { Column, DataTable } from 'components/DataTable/DataTable';
import { RowActions } from 'components/DataTable/RowActions';
import { Member } from 'globals/types/Member';
import { Input, Button } from 'components/forms';
import styles from './TeamsPage.module.scss';
import { Team } from 'globals/types/Team';
import { mockTeams } from './mockTeams';
import { NoContentPanel } from 'components/features';

export default function TeamsPage() {
  // const { teamsList } = useTeams();
  const teamsList = mockTeams;
  const [searchQuery, setSearchQuery] = useState('');

  const handleDelete = (id: number) => {
    console.log('delete team', id);
  };

  const handleCreateTeam = () => {
    console.log('create team');
  };

  const handleEditTeam = (id: number) => {
    console.log('edit team', id);
  };

  const getRowActions = () => {
    return [
      {
        label: 'Edit',
        handler: (team) => {
          handleEditTeam(team.id);
        },
      },
      {
        label: 'Delete',
        handler: (team) => {
          handleDelete(team.id);
        },
      },
    ];
  };

  const filteredTeams = useMemo(() => {
    if (!searchQuery) {
      return teamsList;
    }

    return teamsList.filter((team) => {
      return team.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [teamsList, searchQuery]);

  const columns: Column<Team>[] = [
    {
      key: 'name',
      header: `Teams (${filteredTeams.length})`,
      align: 'left',
    },
    {
      key: 'members',
      header: 'Members',
      align: 'center',
    },
    {
      key: 'manager',
      header: 'Manager',
      align: 'left',
      render: (manager: Member) => manager.name,
    },
    {
      key: 'actions',
      header: '',
      width: '100px',
      align: 'right',
      render: (_, team) => <RowActions item={team} actions={getRowActions()} />,
    },
  ];

  return (
    <>
      <PageHeader title="Teams" />
      <PageContainer>
        {teamsList.length ? (
          <>
            <div className={styles.teamActions}>
              <div>
                <Input
                  placeholder="Search by name or email"
                  type="text"
                  size="medium"
                  containerClassName={styles.searchInput}
                  icon="RiSearchLine"
                  onChange={setSearchQuery}
                  value={searchQuery}
                />
              </div>
              <Button
                icon="RiAddFill"
                variant="primary"
                onClick={() => handleCreateTeam()}
              >
                Create Team
              </Button>
            </div>
            <DataTable data={filteredTeams} columns={columns} />
          </>
        ) : (
          <NoContentPanel
            title={"You don't have any Teams created"}
            subtitle={'Create a new team to get started'}
            buttonTitle="Create team"
            action={() => true}
          />
        )}
      </PageContainer>
    </>
  );
}
