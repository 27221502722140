import React from 'react';
import Tab from './Tab';

import styles from './Tabs.module.scss';

import { TabProps } from './Tab';

type TabsProps = {
  tabs: TabProps[];
};

const Tabs = ({ tabs }: TabsProps): JSX.Element => {
  return (
    <div className={styles.tabsContainer}>
      {tabs.map((tab, index) => (
        <React.Fragment key={index}>
          <Tab label={tab.label} linkTo={tab.linkTo} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default Tabs;
