import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { profileService } from '../services/profileService';
import { authService } from 'globals/services/authService';

export const PROFILE_QUERY_KEY = ['profile'];

export default function useProfile() {
  const queryClient = useQueryClient();

  const profile = useQuery({
    queryKey: PROFILE_QUERY_KEY,
    queryFn: profileService.getProfile,
    enabled: !queryClient.getQueryData(PROFILE_QUERY_KEY),
  });

  if (profile.error) {
    toast.error(profile.error.message);
  }

  const { mutateAsync: updateProfile } = useMutation({
    mutationFn: profileService.updateProfile,
    onSuccess: () => {
      profile.refetch();
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const { mutateAsync: logout } = useMutation({
    mutationFn: authService.logout,
    onSuccess: () => {
      queryClient.setQueryData(PROFILE_QUERY_KEY, null);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  const { mutateAsync: deleteAccount } = useMutation({
    mutationFn: profileService.deleteAccount,
    onSuccess: () => {
      queryClient.setQueryData(PROFILE_QUERY_KEY, null);
    },
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });

  return {
    profile: profile.data,
    isPending: profile.isPending,
    error: profile.error,
    updateProfile,
    logout,
    deleteAccount,
  };
}
