import { create } from 'zustand';
import { getRequest } from 'globals/utils/api';

export const useAgendaStore = create((set) => ({
  isLoading: false,
  setLoading: (status) => set({ isLoading: status }),

  agendaTemplates: [],
  fetchAgendaTemplates: async () => {
    try {
      const result = await getRequest('/agenda/');
      set({ agendaTemplates: result });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  },

  showMeeting: false,
  toggleShowMeeting: () =>
    set((state) => ({ showMeeting: !state.showMeeting })),
}));
