import React from 'react';
import cx from 'classnames';
import styles from './TransparentButton.module.scss';

type TransparentButtonProps = {
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
  className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

function TransparentButton({
  children,
  onClick,
  className,
  ...rest
}: TransparentButtonProps): JSX.Element {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cx(styles.button, className)}
      {...rest}
    >
      {children}
    </button>
  );
}

export default TransparentButton;
