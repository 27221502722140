import React, { ReactElement } from 'react';
import { DropDownItemProps } from './DropDownItem';
import styles from './DropDownMenu.module.scss';

type DropDownGroupProps = {
  children?:
    | ReactElement<DropDownItemProps>
    | ReactElement<DropDownItemProps>[]
    | null;
};

const DropDownGroup: React.FC<DropDownGroupProps> = ({
  children,
}): JSX.Element | null => {
  if (!children) return null;
  return <div className={styles.dropdownGroup}>{children}</div>;
};

export default DropDownGroup;
