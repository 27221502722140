import React, { useState, useRef } from 'react';
import { Input, DropDownMenu } from 'components/forms';
import styles from './Select.module.scss';
import useOnClickOutside from 'globals/hooks/useOnClickOutside';

type Option = {
  label: string;
  value: string | number;
};

type SelectProps = {
  selectedValue: string | number;
  onValueChange: (value: string | number) => void;
  options: Option[][] | Option[];
  size?: 'medium' | 'large';
};

const Select: React.FC<SelectProps> = ({
  selectedValue,
  onValueChange,
  options,
  size = 'medium',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(selectRef, () => setIsOpen(false));

  const toggleDropdownVisibility = () => setIsOpen(!isOpen);

  const selectOption = (value: string | number) => {
    onValueChange(value);
    setIsOpen(false);
  };

  const allOptions = options.flat();
  const currentOption = allOptions.find(
    (option) => option.value === selectedValue,
  );

  return (
    <div className={styles.selectContainer} ref={selectRef}>
      <Input
        value={currentOption?.label}
        size={size}
        readOnly={true}
        onClick={toggleDropdownVisibility}
        icon={isOpen ? 'RiArrowUpSLine' : 'RiArrowDownSLine'}
        iconPosition="right"
      />
      {isOpen && (
        <DropDownMenu
          value={currentOption?.value}
          options={options}
          onSelect={selectOption}
          size={size}
        />
      )}
    </div>
  );
};

export default Select;
