import { AxiosResponse } from 'axios';
import apiClient from 'globals/apiClient';
import { Member } from 'globals/types/Member';

type GetMembersResponse = {
  data: Member[];
  total_count: number;
};

export const membersService = {
  _handleErrors: (error) => {
    if ('errors' in error) {
      let errorText = '';
      for (const key in error.errors) {
        const msg = error.errors[key];
        errorText += `${key}: ${msg}\n`;
      }
      throw new Error(errorText);
    } else {
      throw new Error(error?.response?.data?.detail || 'Something went wrong');
    }
  },
  getMembers: (): Promise<GetMembersResponse> => {
    return apiClient
      .get('/member/')
      .then(({ data }) => data)
      .catch(membersService._handleErrors);
  },

  sendInvitations: (values: { emails: string[] }): Promise<AxiosResponse> => {
    return apiClient
      .post('/member/', values)
      .catch(membersService._handleErrors);
  },

  resendInvitation: (memberId: number): Promise<AxiosResponse> => {
    return apiClient
      .post(`/member/${memberId}/resend-invitation`)
      .catch(membersService._handleErrors);
  },

  deleteMember: (memberId: number): Promise<AxiosResponse> => {
    return apiClient
      .delete(`/member/${memberId}`)
      .catch(membersService._handleErrors);
  },
};
