import { useMutation, useQuery } from '@tanstack/react-query';
import { membersService } from 'globals/services/membersService';

export const MEMBERS_QUERY_KEY = ['members'];

export const useMembers = (fetchList = true) => {
  const membersList = useQuery({
    queryKey: MEMBERS_QUERY_KEY,
    queryFn: () => membersService.getMembers(),
    enabled: fetchList,
    initialData: {
      data: [],
      total_count: 0,
    },
  });

  const inviteMembers = useMutation({
    mutationFn: membersService.sendInvitations,
    onSuccess: () => {
      fetchList && membersList.refetch();
    },
  });

  const resendInvitation = useMutation({
    mutationFn: membersService.resendInvitation,
  });

  const deleteMember = useMutation({
    mutationFn: membersService.deleteMember,
    onSuccess: () => {
      fetchList && membersList.refetch();
    },
  });

  return {
    membersList,
    inviteMembers,
    resendInvitation,
    deleteMember,
  };
};
