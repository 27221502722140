import React from 'react';
import * as RemixIcons from '@remixicon/react';
import { ReactComponent as DollarSign } from 'assets/images/icons/dollar.svg';
import { ReactComponent as DefaultLogo } from 'assets/images/default_logo.svg';
import { ReactComponent as ChromeColor } from 'assets/images/icons/chrome-color.svg';
import { ReactComponent as Facebook } from 'assets/images/icons/facebook.svg';
import { ReactComponent as GoogleColor } from 'assets/images/icons/google-color.svg';
import { ReactComponent as Instagram } from 'assets/images/icons/instagram.svg';
import { ReactComponent as Linkedin } from 'assets/images/icons/linkedin.svg';
import { ReactComponent as X } from 'assets/images/icons/x.svg';

const customIcons = {
  facebook: Facebook,
  instagram: Instagram,
  linkedin: Linkedin,
  X: X,
  'dollar-icon': DollarSign,
  logo: DefaultLogo,
  'google-color': GoogleColor,
  'chrome-color': ChromeColor,
};

type IconProps = {
  name: string;
  size?: number;
  color?: string;
  className?: string;
};

const Icon = ({
  name,
  size = 18,
  color,
  className,
}: IconProps): JSX.Element | null => {
  if (customIcons[name]) {
    const CustomIcon = customIcons[name];
    return (
      <CustomIcon
        width={size}
        height={size}
        fill={color}
        className={className}
      />
    );
  }

  if (name in RemixIcons) {
    const RemixIcon = RemixIcons[name as keyof typeof RemixIcons] as React.FC<{
      size?: number;
      color?: string;
      className?: string;
    }>;
    return <RemixIcon size={size} color={color} className={className} />;
  }

  return null;
};

export default Icon;
