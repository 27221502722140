import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonWithDropDown from 'components/forms/Button/ButtonWithDropDown';
import { UserChip } from 'components/features';
import { useProfile } from 'globals/hooks';
import styles from './ProfileItem.module.scss';

const ProfileItem = () => {
  const { profile, logout } = useProfile();
  const navigate = useNavigate();

  const handleSelect = async (value: string) => {
    if (value === 'Logout') {
      await logout();
      navigate('/login');
    } else if (value === 'Settings') {
      navigate('/settings/profile');
    }
  };

  return (
    <ButtonWithDropDown
      variant="secondary"
      size="medium"
      options={[
        { value: 'Settings', label: 'Profile settings' },
        { value: 'Logout', label: 'Logout' },
      ]}
      onSelect={handleSelect}
      className={styles.profileItem}
      align="left"
      direction="up"
    >
      <UserChip
        userName={profile?.name}
        userPic={profile?.photo_url}
        size="small"
        className={styles.userChip}
      />
    </ButtonWithDropDown>
  );
};

export default ProfileItem;
