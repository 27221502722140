import React, { useState } from 'react';

import { Checkbox } from 'components/forms';

const FeedbackLoop = () => {
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <div>
      <div style={{ margin: '100px 0 ' }}>
        <Checkbox
          label="I agree to the terms and conditions"
          value={checked}
          onChange={handleCheckboxChange}
        />
      </div>
      <div style={{ margin: '100px 0 ' }}></div>
    </div>
  );
};

export default FeedbackLoop;
