import React, { Fragment } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/forms';
import SidebarItem from './SidebarItem/SidebarItem';

import styles from './Sidebar.module.scss';

import { SideBarMenuItem } from './types';

type SubmenuSidebarProps = {
  selectedItem: SideBarMenuItem;
};

const SubmenuSidebar = ({ selectedItem }: SubmenuSidebarProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={classNames(styles.header, styles.submenuHeader)}>
        <Button
          variant="tertiary"
          icon="RiArrowLeftSLine"
          onClick={() => navigate('/')}
          className={styles.backButton}
        />
        <h3>{selectedItem.label}</h3>
      </div>
      <div className={classNames(styles.menu, styles.expanded)}>
        {selectedItem.subMenu?.map((item) => (
          <Fragment key={item.path}>
            <SidebarItem
              icon={item.icon}
              linkTo={`${selectedItem.path}/${item.path}`}
              label={item.label}
              expanded={true}
            />
            {item.separate && <div className={styles.separator} />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default SubmenuSidebar;
