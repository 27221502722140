import React from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Button } from 'components/forms';
import { ReactComponent as Logo } from 'assets/images/logo_image.svg';
import styles from './OnboardingLayout.module.scss';

const buttonMap = {
  '/onboarding/invite-members': {
    text: 'Back to previous step',
    link: '/onboarding/profile',
  },
  '/onboarding/profile': {
    text: 'Sign out',
    link: '/signup',
  },
};

const OnboardingLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { text, link } =
    buttonMap[location.pathname] || buttonMap['/onboarding/profile'];

  return (
    <div className={styles.pageContainer}>
      <div className={styles.header}>
        <Button
          className={styles.signOutButton}
          onClick={() => navigate(link)}
          variant="tertiary"
        >
          {text}
        </Button>
        <div className={styles.logo}>
          <Logo />
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default OnboardingLayout;
