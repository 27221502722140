import React from 'react';

import { Slide, ToastContainer, ToastContainerProps } from 'react-toastify';

import './ToastNotifications.scss';
import {
  RiAlertLine,
  RiCheckboxCircleLine,
  RiInformationLine,
} from '@remixicon/react';

export default function ToastNotifications(
  props: ToastContainerProps,
): JSX.Element {
  return (
    <ToastContainer
      position="top-center"
      autoClose={4000}
      limit={4}
      hideProgressBar
      closeButton={false}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      transition={Slide}
      theme="light"
      icon={({ type }) => {
        switch (type) {
          case 'info':
            return <RiInformationLine />;
          case 'error':
            return <RiAlertLine />;
          case 'success':
            return <RiCheckboxCircleLine />;
          case 'warning':
            return <RiAlertLine />;
          default:
            return null;
        }
      }}
      {...props}
    />
  );
}
