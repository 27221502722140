import React from 'react';
import classNames from 'classnames';
import { Icon } from 'components/forms';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './SidebarItem.module.scss';

type SidebarItemProps = {
  icon?: string;
  label?: string;
  expanded: boolean;
  linkTo?: string;
};

const SidebarItem = ({
  icon,
  label,
  expanded,
  linkTo,
}: SidebarItemProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = linkTo && location.pathname.includes(linkTo);

  const renderIcon = () => {
    if (icon) {
      return (
        <Icon name={isActive ? icon.replace('Line', 'Fill') : icon} size={18} />
      );
    }
    return null;
  };

  return (
    <div
      className={classNames(
        styles.item,
        {
          [styles.active]: isActive,
        },
        't-subheader1',
      )}
      onClick={() => linkTo && navigate(linkTo)}
    >
      {renderIcon()}
      {expanded && <span className={styles.itemLabel}>{label}</span>}
    </div>
  );
};

export default SidebarItem;
