import React from 'react';
import { Sidebar } from 'components/navigation';
import { Outlet } from 'react-router-dom';

import styles from './ProtectedLayout.module.scss';

const ProtectedLayout = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.sidebarContent}>
        <Sidebar />
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default ProtectedLayout;
