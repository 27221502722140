import { SideBarMenu } from 'components/navigation/Sidebar/types';

const sidebarMenuConfig: SideBarMenu = [
  { icon: 'RiGroup3Line', label: 'Team view', path: 'team-view' },
  {
    icon: 'RiAccountCircleLine',
    label: 'Personal view',
    path: 'personal-view',
    separate: true,
  },
  { icon: 'RiFileList2Line', label: 'Agenda', path: 'agenda' },
  { icon: 'RiEmotionHappyLine', label: 'Feedback loop', path: 'feedback-loop' },
  {
    icon: 'RiSettingsLine',
    label: 'Settings',
    path: 'settings',
    subMenu: [
      { label: 'Members', path: 'members' },
      { label: 'Teams', path: 'teams' },
      { label: 'Departments', path: 'departments' },
      { label: 'Wages', path: 'wages', separate: true },
      { label: 'Profile', path: 'profile' },
      { label: 'Notifications', path: 'notifications' },
      { label: 'Billing', path: 'billing' },
      { label: 'Plans', path: 'plans' },
      { label: 'Integrations', path: 'integrations' },
    ],
  },
];

export default sidebarMenuConfig;
