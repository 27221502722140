import React from 'react';
import styled from 'styled-components';
import { Label } from 'components/forms';

const Toggle = ({ label, value, onChange }) => {
  return (
    <ToggleContainer>
      <Label text={label} />
    </ToggleContainer>
  );
};
const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default Toggle;
