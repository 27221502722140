import React from 'react';
import classNames from 'classnames';
import styles from './Loading.module.scss';

type LoadingProps = {
  isLoading: boolean;
  wrapperClassName?: string;
  overlay?: boolean;
};

const Loading = ({
  isLoading,
  wrapperClassName,
  overlay = false,
}: LoadingProps): JSX.Element | null => {
  if (!isLoading) return null;

  const loader = (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
      <div className={styles.spinner} />
    </div>
  );

  return overlay ? <div className={styles.overlay}>{loader}</div> : loader;
};

export default Loading;
