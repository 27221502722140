import React, { FC } from 'react';
import classNames from 'classnames';
import { Icon } from 'components/forms';
import styles from './Input.module.scss';

type InputProps = {
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  description?: string;
  disabled?: boolean;
  readOnly?: boolean;
  icon?: string;
  iconPosition?: 'left' | 'right';
  size?: 'medium' | 'large';
  errorMessage?: string;
  onClick?: () => void;
  className?: string;
  containerClassName?: string;
  [key: string]: any;
};

const Input: FC<InputProps> = ({
  label,
  value,
  onChange,
  placeholder,
  description,
  disabled = false,
  readOnly = false,
  icon,
  iconPosition = 'left',
  size = 'medium',
  errorMessage,
  onClick,
  className,
  containerClassName,
  ...rest
}) => {
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <div className={styles.fieldContainer}>
      {label && (
        <label
          className={classNames(styles.label, styles[size])}
          htmlFor={rest.id}
        >
          {label}
        </label>
      )}
      <div
        className={classNames(
          styles.inputContainer,
          styles[size],
          {
            [styles[`withIcon-${iconPosition}`]]: icon,
            [styles.disabled]: disabled,
            [styles.hasError]: errorMessage,
          },
          containerClassName,
        )}
        onClick={!disabled && onClick ? onClick : undefined}
      >
        {icon && <Icon name={icon} size={18} className={styles.icon} />}
        <input
          className={classNames(styles.input, className)}
          value={value}
          placeholder={placeholder}
          onChange={onChangeValue}
          disabled={disabled}
          readOnly={readOnly}
          {...rest}
        />
      </div>
      {description && <p className={styles.helperText}>{description}</p>}
      {errorMessage && (
        <p className={classNames(styles.helperText, styles.errorText)}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default Input;
