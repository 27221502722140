import React from 'react';
import classNames from 'classnames';
import { RiUserLine } from '@remixicon/react';
import styles from './Avatar.module.scss';

type AvatarProps = {
  src?: string | null;
  size?: 'small' | 'medium' | 'large';
  isRounded?: boolean;
  className?: string;
  iconClassName?: string;
};

const Avatar: React.FC<AvatarProps> = ({
  src,
  size = 'large',
  isRounded = false,
  className,
  iconClassName,
}) => {
  return (
    <div
      className={classNames(
        styles.avatar,
        styles[size],
        { [styles.rounded]: isRounded },
        className,
      )}
      style={{ backgroundImage: `url(${src})` }}
    >
      {!src && (
        <RiUserLine className={classNames(styles.icon, iconClassName)} />
      )}
    </div>
  );
};

export default Avatar;
