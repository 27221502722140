import React from 'react';
import styles from './DataTable.module.scss';
import { RiArrowDropDownFill, RiArrowDropUpFill } from '@remixicon/react';
import classNames from 'classnames';
import { useSortedData } from './useSortedData';
import { Loading } from 'components/forms';

export type Column<TData> = {
  key: keyof TData | 'actions';
  header: string;
  align?: 'left' | 'center' | 'right';
  sortable?: boolean;
  width?: string;
  render?: (value: TData[keyof TData], item: TData) => React.ReactNode;
};

type DataTableProps<TData> = {
  data: TData[];
  columns: Column<TData>[];
  isLoading?: boolean;
};

export const DataTable = <TData extends Record<string, any>>({
  data,
  columns,
  isLoading,
}: DataTableProps<TData>) => {
  const { sortedData, sortConfig, handleSort } = useSortedData(data);

  return (
    <div className={styles.tableContainer}>
      {isLoading && (
        <Loading isLoading={isLoading} wrapperClassName={styles.loading} />
      )}
      <table className={styles.dataTable}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={String(column.key)}
                style={{ width: column.width }}
                onClick={() => column.sortable && handleSort(column.key)}
              >
                <div
                  className={classNames(
                    {
                      [styles.headerCell]: true,
                      [styles.sortable]: Boolean(column.sortable),
                    },
                    styles[`align-${column.align || 'left'}`],
                  )}
                >
                  <span>{column.header}</span>
                  {column.sortable && (
                    <div className={styles.sortIndicator}>
                      <RiArrowDropUpFill
                        className={classNames(styles.sortIcon, {
                          [styles.active]:
                            sortConfig.key === column.key &&
                            sortConfig.direction === 'asc',
                        })}
                        size={20}
                      />
                      <RiArrowDropDownFill
                        className={classNames(styles.sortIcon, {
                          [styles.active]:
                            sortConfig.key === column.key &&
                            sortConfig.direction === 'desc',
                        })}
                        size={20}
                      />
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.length === 0 ? (
            <tr>
              <td colSpan={columns.length} className={styles.noData}>
                No data available
              </td>
            </tr>
          ) : (
            sortedData.map((item, index) => (
              <tr key={index} className={styles.row}>
                {columns.map((column) => (
                  <td key={String(column.key)}>
                    <div
                      className={`${styles.cell} ${styles[`align-${column.align || 'left'}`]}`}
                    >
                      {column.render
                        ? column.render(item[column.key], item)
                        : item[column.key]}
                    </div>
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};
