import { AxiosResponse } from 'axios';
import apiClient from 'globals/apiClient';
import { Profile } from './profileService';

export type GoogleAuthResponse = {
  uri: string;
};

export const authService = {
  authWithGoogle: async (): Promise<GoogleAuthResponse> => {
    const { data } = await apiClient.get<GoogleAuthResponse>('/auth/google');
    return data;
  },

  auth: async (): Promise<Profile> => {
    const { data } = await apiClient.get<Profile>('/auth/');
    return data;
  },

  logout: (): Promise<AxiosResponse> => {
    return apiClient.get('/auth/logout/');
  },
};
