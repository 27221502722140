import React from 'react';
import { colors } from 'styles/colors';
import { Body1, Helper } from 'styles/Typography';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  ReferenceLine,
} from 'recharts';

import styles from './StackedBarChart.module.scss';

type CustomCursorProps = {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
};

const CustomCursor = ({
  x = 0,
  y = 0,
  width = 0,
  height = 0,
}: CustomCursorProps): JSX.Element => {
  const midX = x + width / 2;
  return (
    <g>
      <line
        x1={midX}
        y1={y}
        x2={midX}
        y2={y + height}
        stroke={colors.Neutral[40]}
        strokeWidth={1}
      />
    </g>
  );
};

type TooltipPayloadItem = {
  dataKey: string;
  fill: string;
  value: number;
  payload: Record<string, string | number>;
};

type CustomTooltipProps = {
  active?: boolean;
  payload?: TooltipPayloadItem[];
  label?: string;
};

const CustomTooltip = ({
  payload,
  label,
  active,
}: CustomTooltipProps): JSX.Element | null => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltipContainer}>
        <Body1>{label}</Body1>
        {payload.map((info, index) => (
          <div className={styles.tooltipInfo} key={index}>
            <div className={styles.legendEntry}>
              <div
                className={styles.legendColorBox}
                style={
                  { '--legend-bg-color': info.fill } as React.CSSProperties
                }
              />
              <Helper>{info.dataKey}</Helper>
            </div>
            <Helper>{info.value}</Helper>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

type DataItem = {
  [key: string]: string | number;
};

type BarItem = {
  dataKey: string;
  fill: string;
};

type StackedBarChartProps = {
  title: string;
  data: DataItem[];
  xAxisKey: string;
  bars: BarItem[];
  activeXAxisLabel?: string;
};

const StackedBarChart = ({
  title,
  data,
  xAxisKey,
  bars,
  activeXAxisLabel = '',
}: StackedBarChartProps): JSX.Element => {
  const CustomXAxisLabel = ({ x, y, payload }): JSX.Element => {
    const isSpecialLabel = payload.value === activeXAxisLabel;
    return (
      <g>
        <text
          x={x}
          y={y}
          dy={15}
          textAnchor="middle"
          fill={isSpecialLabel ? colors.Neutral[80] : colors.Neutral[50]}
          fontSize={12}
          fontFamily="Roboto"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const getReferenceLineProps = (data, xAxisKey, activeXAxisLabel) => {
    if (Array.isArray(data)) {
      const index = data.findIndex(
        (item) => item[xAxisKey] === activeXAxisLabel,
      );
      if (index !== -1) {
        return {
          x: activeXAxisLabel,
          stroke: colors.Neutral[40],
          strokeWidth: 1,
        };
      }
    }
    return null;
  };

  const referenceLineProps = getReferenceLineProps(
    data,
    xAxisKey,
    activeXAxisLabel,
  );

  return (
    <div className={styles.chartContainer}>
      <div className={styles.chartHeader}>
        <div className={styles.chartTitle}>{title}</div>
        <div className={styles.legendContainer}>
          {bars.map((bar, index) => (
            <div className={styles.legendEntry} key={index}>
              <div
                className={styles.legendColorBox}
                style={{ '--legend-bg-color': bar.fill } as React.CSSProperties}
              />
              <Helper>{bar.dataKey}</Helper>
            </div>
          ))}
        </div>
      </div>
      <ResponsiveContainer width="100%" height={264}>
        <BarChart
          data={data}
          margin={{ top: 8, right: 28, left: -10, bottom: 24 }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            stroke={colors.Neutral[40]}
            height={1}
            vertical={false}
          />
          <XAxis
            dataKey={xAxisKey}
            tick={CustomXAxisLabel}
            axisLine={{ stroke: colors.Neutral[40] }}
            tickLine={{ stroke: colors.Neutral[40] }}
          />
          <YAxis
            tick={{
              fontSize: 12,
              fontFamily: 'Roboto',
              fill: colors.Neutral[50],
            }}
            interval={0}
            tickCount={6}
            tickLine={false}
            axisLine={false}
          />
          {referenceLineProps && <ReferenceLine {...referenceLineProps} />}
          {bars.map((bar, index) => (
            <Bar
              key={index}
              dataKey={bar.dataKey}
              stackId="a"
              fill={bar.fill}
              maxBarSize={data.length > 28 ? 9 : 18}
            />
          ))}
          <Tooltip content={<CustomTooltip />} cursor={<CustomCursor />} />
          <ReferenceLine y={0} stroke={colors.Neutral[40]} height={1} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StackedBarChart;
