import React from 'react';
import { Button } from 'components/forms';
import styles from './Dialog.module.scss';
import classNames from 'classnames';
import { useContentOverflow } from 'globals/utils/useContentOverflow';

export type DialogProps = {
  isOpen: boolean;
  title: string;
  onDismiss: () => void;
  children: React.ReactNode;
  width?: string;
  dialogActions?: React.ReactNode;
  contentClassName?: string;
};

const Dialog: React.FC<DialogProps> = ({
  isOpen = true,
  title,
  onDismiss,
  children,
  width = 'auto',
  dialogActions,
  contentClassName,
}) => {
  const contentRef = React.useRef<HTMLDivElement>(null);
  const { hasOverflowTop, hasOverflowBottom } = useContentOverflow(contentRef);

  const dialogContentClassNames = classNames(
    styles.dialogContent,
    contentClassName,
    {
      [styles.borderTop]: hasOverflowTop,
      [styles.borderBottom]: hasOverflowBottom,
    },
  );

  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.dialogBox} style={{ width }}>
        <div className={styles.dialogTitle}>
          <h4>{title}</h4>
          <Button
            icon="RiCloseLine"
            variant="tertiary"
            size="small"
            onClick={onDismiss}
          />
        </div>
        <div className={dialogContentClassNames} ref={contentRef}>
          {children}
        </div>
        {dialogActions && (
          <div className={styles.dialogControl}>{dialogActions}</div>
        )}
      </div>
    </div>
  );
};

export default Dialog;
