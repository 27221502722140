import React, { useState } from 'react';
import { Dialog } from 'components';
import { InviteMembersForm } from 'components/features';
import { Button } from 'components/forms';
import { Tooltip as TooltipWrapper } from 'components';
import { usePromiseModal } from '@prezly/react-promise-modal';

type InviteMembersDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onSend: (emails: string[]) => void;
};

export function InviteMembersDialog({
  isOpen,
  onClose,
  onSend,
}: InviteMembersDialogProps) {
  const [emails, setEmails] = useState<string[]>([]);
  const [isValid, setIsValid] = useState<boolean>(false);

  const onEmailsChange = (isValid: boolean, emails: string[]) => {
    setIsValid(isValid);
    setEmails(emails);
  };

  const dialogButtons = (
    <>
      <Button variant="secondary" onClick={onClose}>
        Cancel
      </Button>
      <TooltipWrapper
        overlay="Please add valid emails to send invitations"
        disabled={isValid}
      >
        <Button
          variant="primary"
          onClick={() => onSend(emails)}
          disabled={!isValid}
        >
          Send Invitations
        </Button>
      </TooltipWrapper>
    </>
  );

  return (
    <Dialog
      isOpen={isOpen}
      width="570px"
      title="Invite Members"
      onDismiss={onClose}
      dialogActions={dialogButtons}
    >
      <InviteMembersForm onEmailsChange={onEmailsChange} />
    </Dialog>
  );
}

// use modal as a hook (returns a promise)
export const useInviteMembersDialog = () => {
  return usePromiseModal<string[]>(({ show, onSubmit, onDismiss }) => (
    <InviteMembersDialog
      isOpen={show}
      onClose={onDismiss}
      onSend={(emails) => {
        onSubmit(emails);
      }}
    />
  ));
};
