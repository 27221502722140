import React, { ChangeEvent, useRef } from 'react';
import { Avatar } from 'components';
import { Button } from 'components/forms';

import styles from './ImageEdit.module.scss';
interface ImageEditProps {
  photoPreview: string;
  onPhotoChange: (e: ChangeEvent<HTMLInputElement>) => void;
  autoSubmitOnChange?: boolean;
}

const ImageEdit: React.FC<ImageEditProps> = ({
  photoPreview,
  onPhotoChange,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    fileInputRef.current?.click();
  };

  return (
    <div className={styles.container}>
      <div className={styles.avatarWrapper}>
        <Avatar
          src={photoPreview}
          className={styles.avatar}
          iconClassName={styles.avatarIcon}
        />
        <div className={styles.dimOverlay} />
        <Button
          icon="RiEditLine"
          size="small"
          variant="secondary"
          className={styles.editButton}
          onClick={handleButtonClick}
        />
      </div>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={onPhotoChange}
        className={styles.hiddenInput}
      />
    </div>
  );
};

export default ImageEdit;
